import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { fDateOnly } from "../../utils/formatTime";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  lotgagnants: {
    count: 0,
    data: [],
    num_pages: 1,
    page: 1,
    page_size: "5",
  },
};

const slice = createSlice({
  name: "lotgagnant",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LOT GAGNANT
    getLotGagnantSuccess(state, action) {
      state.isLoading = false;
      state.lotgagnants = action.payload;
    },

    // ADD LOT GAGNANT
    addLotGagnantSuccess(state, action) {
      const lotgagnant = action.payload;
      state.lotgagnants.data.push(lotgagnant);
      state.lotgagnants.count += 1;
    },

    // UPDATE LOT GAGNANTS
    updateLotGagnantSuccess(state, action) {
      const lotgagnant = action.payload;
      if (state.lotgagnants && state.lotgagnants.data) {
        const lotgagnantIndex = state.lotgagnants?.data?.findIndex(
          (obj) => obj.id === lotgagnant.id
        );
        if (lotgagnantIndex !== -1) {
          state.isLoading = false;
          state.lotgagnants.data[lotgagnantIndex] = lotgagnant;
        }
      } else {
        state.lotgagnants = { data: [] };
        state.lotgagnants.data.push(lotgagnant);
      }
    },

    // DELETE LOT GAGNANT
    deleteLotGagnantSuccess(state, action) {
      const deletedLotGagnantId = action.payload;
      state.lotgagnants.data = state.lotgagnants.data.filter(
        (lotgagnant) => lotgagnant.id !== deletedLotGagnantId
      );
      state.isLoading = false;
    },

    // DELETE LOT GAGNANTS
    deleteLotGagnantsSuccess(state, action) {
      const deletedLotGagnantIds = action.payload;
      state.lotgagnants.data = state.lotgagnants.data.filter(
        (lotgagnant) => !deletedLotGagnantIds.includes(lotgagnant.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addLotGagnantSuccess,
  updateLotGagnantSuccess,
  deleteLotGagnantSuccess,
  deleteLotGagnantsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getLotGagnants(
  page = 1,
  rowsPerPage = 5,
  filterStartDate = fDateOnly(new Date(), "yyyy-M-d"),
  filterEndDate = fDateOnly(new Date(), "yyyy-M-d")
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard/lot-gagnants?page=${page}&page_size=${rowsPerPage}&start_date=${filterStartDate}&end_date=${filterEndDate}`
      );
      dispatch(slice.actions.getLotGagnantSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getLotGagnant(keyId) {
  const response = await axios.get(`/api/dashboard/lot-gagnant/${keyId}/`);
  return response.data;
}

export async function addLotGagnant(data) {
  const response = await axios.post(`/api/dashboard/lot-gagnants`, data);
  return response.data;
}

export async function updateLotGagnant(data) {
  const response = await axios.put(
    `/api/dashboard/lot-gagnant/${data?.keyId}/`,
    data
  );
  return response.data;
}

export async function deleteLotGagnant(keyId) {
  const response = await axios.delete(`/api/dashboard/lot-gagnant/${keyId}/`);
  return response.data;
}

export async function deleteLotGagnants(keyIds) {
  const response = await axios.delete(`/api/dashboard/lot-gagnants`, {
    data: { keyIds },
  });
  return response.data;
}
