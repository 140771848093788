import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { List, Collapse } from "@mui/material";
//
import { NavItemRoot, NavItemSub } from "./NavItem";
import { getActive } from "..";
import { handlePermission } from "../../../utils/handlePermission";
// ----------------------------------------------------------------------

NavListRoot.propTypes = {
  isCollapse: PropTypes.bool,
  list: PropTypes.object,
  permissions_list: PropTypes.array,
};

export function NavListRoot({ list, permissions_list, isCollapse }) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          item={list}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => setOpen(!open)}
        />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || []).map((item) =>
                item?.perm ? (
                  handlePermission(permissions_list, item?.perm) && (
                    <NavListSub
                      key={item.title}
                      list={item}
                      permissions_list={permissions_list}
                    />
                  )
                ) : (
                  <NavListSub
                    key={item.title}
                    list={item}
                    permissions_list={permissions_list}
                  />
                )
              )}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />;
}

// ----------------------------------------------------------------------

NavListSub.propTypes = {
  list: PropTypes.object,
  permissions_list: PropTypes.array,
};

function NavListSub({ list, permissions_list }) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub
          item={list}
          onOpen={() => setOpen(!open)}
          open={open}
          active={active}
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) =>
              item?.perm ? (
                handlePermission(permissions_list, item?.perm) && (
                  <NavListSub
                    key={item.title}
                    list={item}
                    active={getActive(item.path, pathname)}
                    permissions_list={permissions_list}
                  />
                )
              ) : (
                <NavListSub
                  key={item.title}
                  list={item}
                  active={getActive(item.path, pathname)}
                  permissions_list={permissions_list}
                />
              )
            )}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={active} />;
}
