import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  option: null,
};

const slice = createSlice({
  name: "option",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET OPTION
    getOptionSuccess(state, action) {
      state.isLoading = false;
      state.option = action.payload;
    },

    // UPDATE OPTION
    updateOptionSuccess(state, action) {
      state.option = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateOptionSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getOption() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/dashboard/options`);
      dispatch(slice.actions.getOptionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateOption(data) {
  const response = await axios.post(`/api/dashboard/options`, data);
  return response.data;
}

export async function deleteOption(id) {
  const response = await axios.delete(`/api/dashboard/option/${id}`);
  return response.data;
}
