import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  limit_ventes: {
    count: 0,
    data: [],
    num_pages: 1,
    page: 1,
    page_size: "5",
  },
};

const slice = createSlice({
  name: "limitvente",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LIMIT VENTE
    getLimitVenteSuccess(state, action) {
      state.isLoading = false;
      state.limit_ventes = action.payload;
    },

    // ADD LIMIT VENTE
    addLimitVenteSuccess(state, action) {
      const limitvente = action.payload;
      state.limit_ventes.data.push(limitvente);
      state.limit_ventes.count += 1;
    },

    // UPDATE LIMIT VENTES
    updateLimitVenteSuccess(state, action) {
      const limitvente = action.payload;
      if (state.limit_ventes && state.limit_ventes.data) {
        const limitventeIndex = state.limit_ventes?.data?.findIndex(
          (obj) => obj.id === limitvente.id
        );
        if (limitventeIndex !== -1) {
          state.isLoading = false;
          state.limit_ventes.data[limitventeIndex] = limitvente;
        }
      } else {
        state.limit_ventes = { data: [] };
        state.limit_ventes.data.push(limitvente);
      }
    },

    // DELETE LIMIT VENTE
    deleteLimitVenteSuccess(state, action) {
      const deletedLimitVenteId = action.payload;
      state.limit_ventes.data = state.limit_ventes.data.filter(
        (limitvente) => limitvente.id !== deletedLimitVenteId
      );
      state.isLoading = false;
    },

    // DELETE LIMIT VENTES
    deleteLimitVentesSuccess(state, action) {
      const deletedLimitVenteIds = action.payload;
      state.limit_ventes.data = state.limit_ventes.data.filter(
        (limitvente) => !deletedLimitVenteIds.includes(limitvente.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addLimitVenteSuccess,
  updateLimitVenteSuccess,
  deleteLimitVenteSuccess,
  deleteLimitVentesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getLimitVentes(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard/limit-vente?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getLimitVenteSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getLimitVente(keyId) {
  const response = await axios.get(
    `/api/dashboard/limit-vente-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addLimitVente(data) {
  const response = await axios.post(`/api/dashboard/limit-vente`, data);
  return response.data;
}

export async function updateLimitVente(data) {
  const response = await axios.put(`/api/dashboard/limit-vente`, data);
  return response.data;
}

export async function deleteLimitVente(keyId) {
  const response = await axios.delete(`/api/dashboard/limit-vente/${keyId}/`);
  return response.data;
}

export async function deleteLimitVentes(keyIds) {
  const response = await axios.delete(`/api/dashboard/limit-vente-id`, {
    data: { keyIds },
  });
  return response.data;
}
